import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyC5LZhG7EwspKbYajgHxDCxT5pAjySd5O8",
  authDomain: "sum-ka-limited.firebaseapp.com",
  databaseURL:
    "https://sum-ka-limited-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "sum-ka-limited",
  storageBucket: "sum-ka-limited.appspot.com",
  messagingSenderId: "100439025275",
  appId: "1:100439025275:web:7d4b7ecfa82c7e77c4e4de",
  measurementId: "G-B4R0KEPDDZ",
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "SUM KA LIMITED"; //公司名稱
      },
      get regFormDomain() {
        return "https://sum-ka.xyz/";
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = "#e8f2fa";

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = "#000";

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
